import React, { useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import _ from "lodash";
import { useIntl, FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import FirstVisitToPulpExchangeModal from "../modals/JoinToPulpExchangeModal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function JoinPulpExchangeRequest(props) {
  const intl = useIntl();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [applicationData, setApplicationData] = useState(false);
  const {
    register,
    unregister,
    handleSubmit,
    triggerValidation,
    getValues,
    control,
    errors,
    formState,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "contacts",
  });
  const { touched, submitCount } = formState;
  const [checked, setCheck] = useState(false);

  const onChangingContactType = (index, type) => {
    fields[index].type = type;
    triggerValidation();
  };

  async function onSubmit(data) {
    setLoading(true);
    if (fields.length) {
      const newFields = _.cloneDeep(fields);
      newFields.forEach((contact, index) => {
        delete contact.id;
        contact.desc = data.contacts[index].desc;
      });
      data.contacts = newFields;
    }
    const postalCode = `${data.postalCode}-${data.postalCode2}`;
    data.postalCode = postalCode;
    delete data.postalCode2;
    setApplicationData(data);
    onChangingModalVisibility();
  }

  const onChangingModalVisibility = () => {
    setOpenModal(!openModal);
  };

  const onSwitchClick = () => {
    if (!checked) {
      unregister("pesel");
    } else {
      unregister("vatin");
    }
    setCheck(!checked);
  };

  return (
    <>
      <FirstVisitToPulpExchangeModal
        open={openModal}
        close={onChangingModalVisibility}
        applicationData={applicationData}
        onAcceptingPhoneNumber={props.onAcceptingPhoneNumber}
        onEnteringConfirmationCode={props.onEnteringConfirmationCode}
      />
      <div className="card card-custom gutter-b">
        <div className="card-header">
          <div className="card-title">
            <span className="agrico-svg-icon svg-icon menu-icon mr-2">
              <SVG src={toAbsoluteUrl("/media/icons/User.svg")} />
            </span>
            <h3 className="card-label">
              <FormattedMessage id="OFFERS.JOIN_PULP_EXCHANGE" />
            </h3>
          </div>
        </div>
        <div className="card-body">
          {(props.applicationStatus === "U" ||
            props.applicationStatus === "R") && (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Row>
                <div className="col-xxl-12 mb-2">
                  <FormattedMessage id="OFFERS.JOIN_PULP_EXCHANGE_MESSAGE" />:
                </div>
                <Form.Group as={Col} sm={6}>
                  <Form.Label>
                    <FormattedMessage id="PE.SUGAR_FACTORY" /> *
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="orgentityId"
                    ref={register({ required: true })}
                  >
                    {props.factories.map((factory) => (
                      <option
                        key={factory.orgentityId}
                        value={factory.orgentityId}
                      >
                        {factory.orgentityDesc}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                  <Form.Label>
                    <FormattedMessage id="GENERAL.CITY" /> *
                  </Form.Label>
                  <Form.Control
                    name="address1"
                    type="text"
                    className={`${
                      (touched?.address1 &&
                        getValues("address1").length === 0) ||
                      (submitCount >= 1 &&
                        getValues("address1")?.length === 0) ||
                      errors.address1
                        ? "is-invalid"
                        : ""
                    } ${
                      !errors.address1 && getValues("address1")?.length >= 1
                        ? "is-valid"
                        : ""
                    }`}
                    placeholder={`${intl.formatMessage({
                      id: "PLACEHOLDER.CITY_NAME",
                    })}`}
                    ref={register({ required: true })}
                  />
                  {(errors.address1 ||
                    (touched?.address1 && getValues("address1").length === 0) ||
                    (submitCount >= 1 &&
                      getValues("address1")?.length === 0)) && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                    </div>
                  )}
                </Form.Group>
                <Form.Group as={Col} sm={4}>
                  <Form.Label>
                    <FormattedMessage id="GENERAL.STREET_ADDRESS" /> *
                  </Form.Label>
                  <Form.Control
                    name="address2"
                    type="text"
                    className={`${
                      (touched?.address2 &&
                        getValues("address2").length === 0) ||
                      (submitCount >= 1 &&
                        getValues("address2")?.length === 0) ||
                      errors.address2
                        ? "is-invalid"
                        : ""
                    } ${
                      !errors.address2 && getValues("address2")?.length >= 1
                        ? "is-valid"
                        : ""
                    }`}
                    placeholder={`${intl.formatMessage({
                      id: "PLACEHOLDER.STREET_ADDRESS",
                    })}`}
                    ref={register({ required: true })}
                  />
                  {(errors.address2 ||
                    (touched?.address2 && getValues("address2").length === 0) ||
                    (submitCount >= 1 &&
                      getValues("address2")?.length === 0)) && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                    </div>
                  )}
                </Form.Group>
                <Form.Group as={Col} sm={4}>
                  <Form.Label>
                    <FormattedMessage id="GENERAL.HOUSE_NUMBER" /> *
                  </Form.Label>
                  <Form.Control
                    name="houseNumber"
                    type="text"
                    className={`${
                      (touched?.houseNumber &&
                        getValues("houseNumber").length === 0) ||
                      (submitCount >= 1 &&
                        getValues("houseNumber")?.length === 0) ||
                      errors.houseNumber
                        ? "is-invalid"
                        : ""
                    } ${
                      !errors.houseNumber &&
                      getValues("houseNumber")?.length >= 1
                        ? "is-valid"
                        : ""
                    }`}
                    placeholder={`${intl.formatMessage({
                      id: "PLACEHOLDER.HOUSE_NUMBER",
                    })}`}
                    ref={register({ required: true })}
                  />
                  {(errors.houseNumber ||
                    (touched?.houseNumber &&
                      getValues("houseNumber").length === 0) ||
                    (submitCount >= 1 &&
                      getValues("houseNumber")?.length === 0)) && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                    </div>
                  )}
                </Form.Group>
                <Form.Group as={Col} sm={4}>
                  <Form.Label>
                    <FormattedMessage id="GENERAL.APARTAMENT_NUMBER" />
                  </Form.Label>
                  <Form.Control
                    name="apartamentNumber"
                    type="text"
                    placeholder={`${intl.formatMessage({
                      id: "PLACEHOLDER.APARTAMENT_NUMBER",
                    })}`}
                    ref={register({ required: false })}
                  />
                </Form.Group>
                <Form.Group as={Col} sm={3}>
                  <Form.Label>
                    <FormattedMessage id="GENERAL.COUNTRY_CODE" /> *
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="countryId"
                    ref={register({ required: true })}
                  >
                    {props.postalCodes.map((countryCode) => (
                      <option key={countryCode.id} value={countryCode.id}>
                        {countryCode.code}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} sm={4}>
                  <Form.Label>
                    <FormattedMessage id="GENERAL.POSTAL_CODE" /> *
                  </Form.Label>
                  <Form.Control
                    name="postalCode"
                    type="number"
                    className={`
                    ${
                      (touched.postalCode &&
                        getValues("postalCode")?.length !== 2) ||
                      (errors.postalCode && submitCount >= 1) ||
                      ((errors.postalCode?.type === "minLength" ||
                        errors.postalCode?.type === "maxLength") &&
                        touched.postalCode)
                        ? "is-invalid"
                        : ""
                    } ${
                      (touched?.postalCode &&
                        getValues("postalCode")?.length === 2) ||
                      (!errors.postalCode &&
                        getValues("postalCode")?.length === 2)
                        ? "is-valid"
                        : ""
                    }`}
                    placeholder="00"
                    ref={register({
                      required: true,
                      maxLength: 2,
                      minLength: 2,
                    })}
                  />
                  {(errors.postalCode?.type === "required" ||
                    (touched?.postalCode &&
                      getValues("postalCode")?.length === 0) ||
                    (submitCount >= 1 &&
                      getValues("postalCode")?.length === 0)) && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                    </div>
                  )}
                  {(errors.postalCode?.type === "minLength" ||
                    errors.postalCode?.type === "maxLength") &&
                    (touched.postalCode || submitCount >= 1) && (
                      <div
                        className="error invalid-feedback"
                        style={{ display: "block" }}
                      >
                        <FormattedMessage id="ERROR.INVALID_FORMAT" />
                      </div>
                    )}
                </Form.Group>
                <Col
                  sm={1}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  -
                </Col>
                <Form.Group as={Col} sm={4}>
                  <Form.Label>
                    <span style={{ visibility: "hidden" }}>.</span>
                  </Form.Label>
                  <Form.Control
                    name="postalCode2"
                    type="number"
                    className={`${
                      (touched.postalCode2 &&
                        getValues("postalCode2")?.length !== 3) ||
                      (errors.postalCode2 && submitCount >= 1) ||
                      ((errors.postalCode2?.type === "minLength" ||
                        errors.postalCode2?.type === "maxLength") &&
                        touched.postalCode2)
                        ? "is-invalid"
                        : ""
                    } ${
                      (touched?.postalCode2 &&
                        getValues("postalCode2")?.length === 3) ||
                      (!errors.postalCode2 &&
                        getValues("postalCode2")?.length === 3)
                        ? "is-valid"
                        : ""
                    }`}
                    placeholder="000"
                    ref={register({
                      required: true,
                      maxLength: 3,
                      minLength: 3,
                    })}
                  />
                  {(errors.postalCode2?.type === "required" ||
                    (touched?.postalCode2 &&
                      getValues("postalCode2")?.length === 0) ||
                    (submitCount >= 1 &&
                      getValues("postalCode2")?.length === 0)) && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                    </div>
                  )}
                  {(errors.postalCode2?.type === "minLength" ||
                    errors.postalCode2?.type === "maxLength") &&
                    (touched.postalCode2 || submitCount >= 1) && (
                      <div
                        className="error invalid-feedback"
                        style={{ display: "block" }}
                      >
                        <FormattedMessage id="ERROR.INVALID_FORMAT" />
                      </div>
                    )}
                </Form.Group>
                <Col
                  sm={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <FormattedMessage id="GENERAL.PERSON" />
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label=""
                    checked={checked}
                    onChange={() => onSwitchClick()}
                  />
                  <FormattedMessage id="GENERAL.COMPANY" />
                </Col>
                {checked === true ? (
                  <Form.Group as={Col} sm={6}>
                    <Form.Label>
                      <FormattedMessage id="GENERAL.NIP" /> *
                    </Form.Label>
                    <Form.Control
                      name="vatin"
                      type="text"
                      className={`${
                        (touched?.vatin && getValues("vatin").length === 0) ||
                        (submitCount >= 1 && getValues("vatin")?.length === 0) ||
                        errors.vatin?.type === "required" ||
                        (touched?.vatin && errors.vatin?.type === "pattern")
                          ? "is-invalid"
                          : ""
                      } ${
                        !errors.vatin && getValues("vatin")?.length === 10
                          ? "is-valid"
                          : ""
                      }`}
                      placeholder="NIP"
                      ref={register({
                        required: true,
                        pattern: /^([A-Za-z]{2})?[0-9]{10}$/,
                      })}
                    />
                    {/* pattern: /^[A-Za-z]{2,4}(?=.{2,12}$)[-_\s0-9]*(?:[a-zA-Z][-_\s0-9]*){0,2}$/, */}
                    {(errors.vatin?.type === "required" ||
                      (touched?.vatin && getValues("vatin").length === 0) ||
                      (submitCount >= 1 && getValues("vatin")?.length === 0)) && (
                      <div
                        className="error invalid-feedback"
                        style={{ display: "block" }}
                      >
                        <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                      </div>
                    )}
                    {touched.vatin && errors.vatin?.type === "pattern" && (
                      <div className="error invalid-feedback">
                        <FormattedMessage id="ERROR.INVALID_FORMAT" />
                      </div>
                    )}
                  </Form.Group>
                ) : (
                  <Form.Group as={Col} sm={6}>
                    <Form.Label>
                      <FormattedMessage id="GENERAL.PESEL" /> *
                    </Form.Label>
                    <Form.Control
                      name="pesel"
                      type="text"
                      className={`${
                        (touched?.pesel && getValues("pesel").length === 0) ||
                        (submitCount >= 1 &&
                          getValues("pesel")?.length === 0) ||
                        errors.pesel?.type === "required" ||
                        (touched?.pesel && errors.pesel?.type === "pattern")
                          ? "is-invalid"
                          : ""
                      } ${
                        !errors.pesel && getValues("pesel")?.length === 11
                          ? "is-valid"
                          : ""
                      }`}
                      placeholder="PESEL"
                      ref={register({ required: true, pattern: /^[0-9]{11}$/ })}
                    />
                    {(errors.pesel?.type === "required" ||
                      (touched?.pesel && getValues("pesel").length === 0) ||
                      (submitCount >= 1 &&
                        getValues("pesel")?.length === 0)) && (
                      <div
                        className="error invalid-feedback"
                        style={{ display: "block" }}
                      >
                        <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                      </div>
                    )}
                    {touched.pesel && errors.pesel?.type === "pattern" && (
                      <div className="error invalid-feedback">
                        <FormattedMessage id="ERROR.INVALID_FORMAT" />
                      </div>
                    )}
                  </Form.Group>
                )}
                <Form.Group as={Col} sm={6}>
                  <Form.Label>
                    <FormattedMessage id="GENERAL.NAME" /> *
                  </Form.Label>
                  <Form.Control
                    name="fullName"
                    type="text"
                    className={`${
                      (touched?.fullName &&
                        getValues("fullName").length === 0) ||
                      (submitCount >= 1 &&
                        getValues("fullName")?.length === 0) ||
                      errors.fullName
                        ? "is-invalid"
                        : ""
                    } ${
                      !errors.fullName && getValues("fullName")?.length >= 1
                        ? "is-valid"
                        : ""
                    }`}
                    placeholder={`${intl.formatMessage({
                      id: "PLACEHOLDER.NAME",
                    })}`}
                    ref={register({ required: true })}
                  />
                  {(errors.fullName ||
                    (touched?.fullName && getValues("fullName").length === 0) ||
                    (submitCount >= 1 &&
                      getValues("fullName")?.length === 0)) && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                    </div>
                  )}
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                  <Form.Label>
                    <FormattedMessage id="GENERAL.SHORT_NAME" /> *
                  </Form.Label>
                  <Form.Control
                    name="shortName"
                    type="text"
                    className={`${
                      (touched?.shortName &&
                        getValues("shortName").length === 0) ||
                      (submitCount >= 1 &&
                        getValues("shortName")?.length === 0) ||
                      getValues("shortName")?.length > 10 ||
                      errors.shortName
                        ? "is-invalid"
                        : ""
                    } ${
                      !errors.shortName &&
                      getValues("shortName")?.length >= 1 &&
                      getValues("shortName")?.length <= 10
                        ? "is-valid"
                        : ""
                    }`}
                    placeholder={`${intl.formatMessage({
                      id: "PLACEHOLDER.SHORT_NAME",
                    })}`}
                    ref={register({ required: true, maxLength: 10 })}
                  />
                  {(errors.shortName?.type === "required" ||
                    (touched?.shortName &&
                      getValues("shortName").length === 0) ||
                    (submitCount >= 1 &&
                      getValues("shortName")?.length === 0)) && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                    </div>
                  )}
                  {errors.shortName?.type === "maxLength" &&
                    (touched.shortName || submitCount >= 1) && (
                      <div
                        className="error invalid-feedback"
                        style={{ display: "block" }}
                      >
                        <FormattedMessage id="ERROR.INCORRECT_SHORT_NAME_LENGTH" />
                      </div>
                    )}
                </Form.Group>
                {fields.length > 0 && (
                  <div className="col-xxl-12 mb-2">
                    <h6>
                      <FormattedMessage id="GENERAL.CONTACTS" />:
                    </h6>
                  </div>
                )}
                {fields.map((item, index) => (
                  <Form.Group as={Col} sm={12} key={item.id}>
                    <Form.Label>
                      {item.type === "M" && (
                        <FormattedMessage id="GENERAL.EMAIL" />
                      )}
                      {item.type === "T" && (
                        <FormattedMessage id="GENERAL.PHONE" />
                      )}
                      {item.type === "C" && (
                        <FormattedMessage id="GENERAL.MOBILE" />
                      )}
                      {item.type === "F" && (
                        <FormattedMessage id="GENERAL.FAX" />
                      )}
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <Button
                          variant={
                            item.type === "M" ? "primary" : "outline-secondary"
                          }
                          onClick={() => onChangingContactType(index, "M")}
                        >
                          <i className="fa fa-at icon-nm pr-0"></i>
                        </Button>
                        <Button
                          variant={
                            item.type === "T" ? "primary" : "outline-secondary"
                          }
                          onClick={() => onChangingContactType(index, "T")}
                        >
                          <i className="fa fa-phone-alt icon-nm pr-0"></i>
                        </Button>
                        <Button
                          variant={
                            item.type === "C" ? "primary" : "outline-secondary"
                          }
                          onClick={() => onChangingContactType(index, "C")}
                        >
                          <i className="fa fa-mobile-alt icon-nm pr-0"></i>
                        </Button>
                        <Button
                          variant={
                            item.type === "F" ? "primary" : "outline-secondary"
                          }
                          onClick={() => onChangingContactType(index, "F")}
                        >
                          <i className="fa fa-fax icon-nm pr-0"></i>
                        </Button>
                        <Button
                          variant="outline-secondary"
                          onClick={() => remove(index)}
                        >
                          <i className="fa fa-trash icon-nm pr-0"></i>
                        </Button>
                      </InputGroup.Prepend>
                      <Form.Control
                        name={`contacts[${index}].desc`}
                        type="text"
                        placeholder={`${intl.formatMessage({
                          id: "PLACEHOLDER.EMAIL_OR_PHONE",
                        })}`}
                        defaultValue={item.desc}
                        ref={register()}
                      />
                    </InputGroup>
                  </Form.Group>
                ))}
              </Form.Row>
              <div className="d-flex align-items-center justify-content-between mb-2">
                <Button
                  variant="primary"
                  type="button"
                  disabled={loading}
                  onClick={() => append({ type: "M", desc: "" })}
                >
                  <FormattedMessage id="GENERAL.ADD_CONTACT" />
                </Button>
                <Button type="submit" disabled={loading}>
                  <FormattedMessage id="GENERAL.SAVE" />
                </Button>
              </div>
            </Form>
          )}
          {props.applicationStatus === "E" && (
            <>
              <div className="col-xxl-12">
                <FormattedMessage id="OFFERS.REQUEST_HAS_BEEN_SENT" />.
              </div>
              <div className="col-xxl-12 font-weight-bold">
                <FormattedMessage id="GENERAL.PLEASE_BE_PATIENT" />!
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
