import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Redirect } from "react-router-dom";
import moment from "moment";
import useEffectOnce from "../../../hooks/useEffectOnce";
import { isMobile } from "react-device-detect";
import {
  PDFViewer,
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { getLicence } from "../../../services/licenceCrud";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import font from "../../../../fonts/Roboto-Light.ttf";
import errorHandler from "../../../shared/errorHandler";
import displayFeedback from "../../../components/helpers/displayFeedback";

Font.register({ family: "Robot", src: font });

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: "Robot",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  growerTitle: {
    fontSize: 11,
    textTransform: "uppercase",
  },
  textLight: {
    fontSize: 11,
    fontWeight: 400,
  },
  container: {
    paddingTop: 40,
  },
  title: {
    fontSize: 12,
    textAlign: "center",
    textTransform: "uppercase",
  },
  box: {
    paddingTop: 20,
  },
  rightBox: {
    paddingTop: 40,
    fontSize: 12,
    fontWeight: 400,
    textAlign: "right",
  },
  text: {
    fontSize: 12,
    fontWeight: 400,
    textAlign: "justify",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

const MyDocument = (props) => (
  <Document>
    <Page size="A4" style={styles.body}>
      <View style={styles.header}>
        <View>
          <Text style={styles.growerTitle}>Dane plantatora</Text>
          <Text style={styles.textLight}>
            Nr plantatora: {props.licenceData.groOwner.groOwnerNo}
          </Text>
          <Text style={styles.textLight}>
            {props.licenceData.groOwner.bpartnerFullName}
          </Text>
          <Text style={styles.textLight}>
            {props.licenceData.groOwner.address1}{" "}
            {props.licenceData.groOwner.address2}
          </Text>
          <Text style={styles.textLight}>
            {props.licenceData.groOwner.postalCode}{" "}
            {props.licenceData.groOwner.cityName}
          </Text>
        </View>
        <View>
          <Text style={styles.textLight}>
            {moment(props.licenceData.licenceCreatedAt).format("DD.MM.YYYY")} r.
          </Text>
        </View>
      </View>

      <View style={styles.container}>
        <Text style={styles.title}>
          POWIADOMIENIE O OSOBIE UPRAWNIONEJ DO ODBIORU WYSŁODKÓW
        </Text>
      </View>
      <View style={styles.box}>
        <Text style={styles.text}>
          Ja niżej podpisany informuję, że osobą uprawnioną do odbioru wysłodków
          prasowanych w ilości {props.licenceData.primaryWeight} ton jest:
          {/* wysłodków należnych 20 % ss w ilości {props.licenceData.primaryWeight} ton jest: */}
        </Text>
      </View>
      <View style={styles.box}>
        <View>
          <Text style={styles.textLight}>
            {props.licenceData.supCust.bpartnerFullName}
          </Text>
          <Text style={styles.textLight}>
            {props.licenceData.supCust.address2}
          </Text>
          <Text style={styles.textLight}>
            {props.licenceData.supCust.postalCode}{" "}
            {props.licenceData.supCust.address1}
          </Text>
          <Text style={styles.textLight}>
            Tel.: {props.licenceData.supCust.phoneNumber}
          </Text>
        </View>
      </View>
      <View style={styles.box}>
        <Text style={styles.text}>
          Do odebrania transportem własnym z zakładu{" "}
          {props.licenceData.orgentityDesc}.
        </Text>
      </View>
      <View style={styles.box}>
        <Text style={styles.text}>
          Numer transakcji (AGRICO): {props.licenceData.sellOfferId}/
          {props.licenceData.buyOfferId}
        </Text>
      </View>

      <View style={styles.rightBox}>
        <Text>
          Zatwierdzono elektronicznie w dniu{" "}
          {moment(props.licenceData.paidAt).format("DD.MM.YYYY")}
        </Text>
        <Text>Kod autoryzacji elektronicznej: {props.licenceData.pin}</Text>
      </View>

      <View style={styles.box}>
        <Text style={styles.text}>
          Termin odbioru wysłodków uzależniony jest od możliwości poszczególnych
          zakładów produkcyjnych. Odbiór wysłodków należy zaawizować podając
          numer rejestracyjny pojazdu.
        </Text>
      </View>
      <View style={styles.box}>
        <Text style={styles.text}>
          W celu ustalenia terminu odbioru towaru należy skontaktować się z
          koordynatorem do spraw wysłodków.
        </Text>
      </View>

      <View style={styles.box}>
        <Text style={styles.text}>Koordynator:</Text>
        <Text style={styles.growerTitle}>
          {/* Jolanta Wojtowicz */} Jan Kowalski
        </Text>
        <Text style={styles.text}>
          {/* Nr telefonu: +48 607 210 773 */}
          Nr telefonu: +48 000 111 222
        </Text>
        <Text style={styles.text}>
          {/* e-mail: Jolanta.Wojtowicz@strzelin.suedzucker.pl */}
          e-mail: jan.kowalski@random.ts
        </Text>
      </View>
    </Page>
  </Document>
);

export function LicencePage(props) {
  const intl = useIntl();
  const [licenceData, setLicenceData] = useState();
  const params = new URLSearchParams(props.location.search);
  const licenceId = params.get("id");

  const getLicenceForPDF = () => {
    if (licenceId) {
      getLicence(licenceId)
        .then((response) => {
          setLicenceData(response.data);
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    }
  };

  useEffectOnce(getLicenceForPDF);

  return (
    <>
      <Row style={{ height: "100%" }}>
        {licenceId ? (
          <>
            {licenceData && !isMobile && (
              <Col md={12} lg={12} xxl={12}>
                <PDFViewer style={{ width: "100%", height: "100%" }}>
                  <MyDocument licenceData={licenceData} />
                </PDFViewer>
              </Col>
            )}
            {licenceData && isMobile && (
              <Col
                md={12}
                lg={12}
                xxl={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItem: "center",
                  justifyContent: "center",
                }}
              >
                <div className="text-center">
                  <PDFDownloadLink
                    document={<MyDocument licenceData={licenceData} />}
                    fileName={`licensing-${moment().format(
                      "DD.MM.YYYY_HH:mm"
                    )}.pdf`}
                  >
                    <Button>
                      <FormattedMessage id="GENERAL.CLICK_HERE_TO_DOWNLOAD_PDF" />
                    </Button>
                  </PDFDownloadLink>
                </div>
              </Col>
            )}
          </>
        ) : (
          <Redirect to="/pulp-exchange/offers" />
        )}
      </Row>
    </>
  );
}

export default LicencePage;
