import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { Table, Badge, Button } from "react-bootstrap";

export function ContractorsTransactionsReport(props) {
  return (
    <>
      {props.report ? (
        <Table striped bordered hover responsive className="mt-2">
          <thead>
            <tr className="text-center">
              <th>
                <FormattedMessage id="GENERAL.STATUS" />
              </th>
              <th>
                <FormattedMessage id="PE.SUGAR_FACTORY" />
              </th>
              <th>
                <FormattedMessage id="PE.GROWER_NUMBER" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.GROWER" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.STATUS_SYMBOL" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.AUTHORIZATION_CODE" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.ACTIONS" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.COMMENT" />
              </th>
              <th>
                <FormattedMessage id="PE.TRANSACTION_DATE" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.ID" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.CONTRACTOR" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.NIP" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.PESEL" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.ADDRESS" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.TELEPHONE_NUMBER" />
              </th>
              <th>
                <FormattedMessage id="PE.SALE_OFFER_ID" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.OFFER_DATE" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.EXPIRATION_DATE" />
              </th>
              <th>
                <FormattedMessage id="PE.TRANSACTION_NUMBER" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.WEIGHT" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.PRICE" />
              </th>
            </tr>
          </thead>
          <tbody>
            {props.report.map((row, index) => (
              <tr key={index}>
                <td className="text-center">
                  {row.buyOfferStatus === "W" && (
                    <Badge pill variant="info">
                      <FormattedMessage id="GENERAL.WAITING" />
                    </Badge>
                  )}
                  {row.buyOfferStatus === "A" && (
                    <Badge pill variant="success">
                      <FormattedMessage id="GENERAL.ACCEPTED" />
                    </Badge>
                  )}
                  {row.buyOfferStatus === "N" && (
                    <Badge pill variant="warning">
                      <FormattedMessage id="GENERAL.NEGOTIATION" />
                    </Badge>
                  )}
                  {row.buyOfferStatus === "R" && (
                    <Badge pill variant="danger">
                      <FormattedMessage id="GENERAL.REJECTED" />
                    </Badge>
                  )}
                </td>
                <td className="text-center">{row.orgentityDesc}</td>
                <td className="text-center">{row.growerNo}</td>
                <td className="text-center">{row.groOwnerFullName}</td>
                <td className="text-center">
                  {(!row.buyOfferStatus || row.buyOfferStatus === "W") && (
                    <Badge pill variant="info">
                      W
                    </Badge>
                  )}
                  {row.buyOfferStatus === "A" && (
                    <Badge pill variant="success">
                      A
                    </Badge>
                  )}
                  {row.buyOfferStatus === "N" && (
                    <Badge pill variant="warning">
                      N
                    </Badge>
                  )}
                  {row.buyOfferStatus === "R" && (
                    <Badge pill variant="danger">
                      R
                    </Badge>
                  )}
                </td>
                <td className="text-center">{row.pin}</td>
                <td className="text-center">
                  {row.licenceId && row.licenceSymbol === "P" && (
                    <Button
                      type="button"
                      variant="primary"
                      className="button--gw_active_background"
                    >
                      <Link
                        to={`/pulp-exchange/licence?id=${row.licenceId}`}
                        target="_blank"
                        className="button__link--primary"
                      >
                        <FormattedMessage id="GENERAL.PRINT_LICENSING" />
                      </Link>
                    </Button>
                  )}
                  {row.licenceId && row.licenceSymbol !== "P" && (
                    <Button
                      type="button"
                      variant="primary"
                      className="button--gw_active_background"
                      disabled={true}
                    >
                      <FormattedMessage id="GENERAL.PRINT_LICENSING" />
                    </Button>
                  )}
                </td>
                <td className="text-center">{row.comment}</td>
                <td className="text-center">
                  {moment(row.buyOfferCreatedAt).format("DD.MM.YYYY HH:mm")}
                </td>
                <td className="text-center">{row.supCustId}</td>
                <td className="text-center">{row.supCustFullName}</td>
                <td className="text-center">{row.vatin}</td>
                <td className="text-center">{row.pesel}</td>
                <td className="text-center">
                  {row.postalCode} {row.address1}, {row.address2}
                </td>
                <td className="text-center">{row.phoneNumber}</td>
                <td className="text-center">{row.sellOfferId}</td>
                <td className="text-center">
                  {moment(row.sellOfferCreatedAt).format("DD.MM.YYYY HH:mm")}
                </td>
                <td className="text-center">
                  {moment(row.expiredDate).format("DD.MM.YYYY HH:mm")}
                </td>
                <td className="text-center">{row.buyOfferId}</td>
                <td className="text-right">{row.buyOfferWeight}</td>
                <td className="text-right">{row.buyOfferPrice}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="text-center mt-2">
          <h6>
            <FormattedMessage id="PE.NO_REPORTS" />
          </h6>
        </div>
      )}
    </>
  );
}
