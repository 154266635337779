import React, { useState, useEffect } from "react";
import {useIntl, FormattedMessage} from "react-intl";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import { enGB, de, pl, ru } from "date-fns/locale";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";

export function ReportsForm(props) {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit } = useForm({
    mode: "onChange",
  });
  const intl = useIntl();
  const [datepickerLocale, setDatepickerLocale] = useState("pl");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [buyOfferStatus] = useState([
    { id: 1, symbol: "A", desc: "Zaakceptowany" },
    { id: 2, symbol: "R", desc: "Odrzucony" },
    { id: 3, symbol: "N", desc: "Negocjacja" },
    { id: 4, symbol: "W", desc: "Oczekujący" },
    { id: 5, symbol: "E", desc: "Każdy" },
  ]);
  const [sellOfferStatus] = useState([
    { id: 1, symbol: "A", desc: "Opublikowany" },
    { id: 2, symbol: "C", desc: "Usunięty" },
    { id: 3, symbol: "F", desc: "Zakończony" },
    { id: 4, symbol: "E", desc: "Przeterminowany" },
    { id: 5, symbol: "W", desc: "Każdy" },
  ]);
  registerLocale("pl", pl);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "en") {
      registerLocale("enGB", enGB);
      setDatepickerLocale("enGB");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "pl"
    ) {
      registerLocale("pl", pl);
      setDatepickerLocale("pl");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "de"
    ) {
      registerLocale("de", de);
      setDatepickerLocale("de");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "ru"
    ) {
      registerLocale("ru", ru);
      setDatepickerLocale("ru");
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = (data) => {
    const form = data;
    form.type = props.type || "GROOWNERS_SELL_OFFER";
    setLoading(true);
    if (form.supCustStatusId === "2020") {
      form.supCustStatusId = null;
    }
    if (
      props.type === "MOBILE_DELIVERY" ||
      props.type === "CONTRACTOR_BUY_OFFER" ||
      props.type === "GROOWNERS_BUY_OFFER" ||
      props.type === "MOBILE_DIAGNOSTIC"  
    ) {
      form.dateFrom = fromDate;
      form.dateTo = toDate;
    }
    props.onCreatingReport(form);
    setLoading(false);
  };

  const onExportingReport = () => {
    props.onExportingReportToExcel();
  };

  const onChangingDate = (which, date) => {
    if (which === "from") {
      setFromDate(date);
    } else {
      setToDate(date);
    }
  };

  return (
    <>
      <Form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          {props.type === "CONTRACTOR_DATA" && (
            <Form.Group as={Col} md={12}>
              <Form.Label>
                <FormattedMessage id="GENERAL.STATUS" />
              </Form.Label>
              <Form.Control
                as="select"
                name="supCustStatusId"
                ref={register({ required: true })}
              >
                {props?.status.map((status) => (
                  <option key={status.symbol} value={status.supCustStatusId}>
                    {status.desc}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}
          {(props.type === "MOBILE_DELIVERY" ||
            props.type === "CONTRACTOR_BUY_OFFER" ||
            props.type === "GROOWNERS_BUY_OFFER" || 
            props.type === "MOBILE_DIAGNOSTIC") && (
            <>
              <Form.Group as={Col} md={6}>
                <Form.Label style={{ display: "block" }}>
                  <FormattedMessage id="GENERAL.DATE_FROM" />
                </Form.Label>
                <DatePicker
                  name="dateFrom"
                  selected={fromDate}
                  dateFormat="dd.MM.yyyy"
                  locale={datepickerLocale}
                  showMonthDropdown
                  showYearDropdown
                  onChange={(date) => onChangingDate("from", date)}
                />
              </Form.Group>
              <Form.Group as={Col} md={6}>
                <Form.Label style={{ display: "block" }}>
                  <FormattedMessage id="GENERAL.DATE_TO" />
                </Form.Label>
                <DatePicker
                  name="dateTo"
                  selected={toDate}
                  dateFormat="dd.MM.yyyy"
                  locale={datepickerLocale}
                  showMonthDropdown
                  showYearDropdown
                  onChange={(date) => onChangingDate("to", date)}
                />
              </Form.Group>
            </>
          )}
          {props.type === "CONTRACTOR_BUY_OFFER" && (
            <Form.Group as={Col} md={12}>
              <Form.Label>
                <FormattedMessage id="GENERAL.STATUS" />
              </Form.Label>
              <Form.Control
                as="select"
                name="buyOfferStatus"
                ref={register({ required: true })}
              >
                {buyOfferStatus.map((status) => (
                  <option key={status.id} value={status.symbol}>
                    {status.desc}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}
          {props.type === "GROOWNERS_BUY_OFFER" && (
            <Form.Group as={Col} md={12}>
              <Form.Label>
                <FormattedMessage id="GENERAL.STATUS" />
              </Form.Label>
              <Form.Control
                as="select"
                name="sellOfferStatus"
                ref={register({ required: true })}
              >
                {sellOfferStatus.map((status) => (
                  <option key={status.id} value={status.symbol}>
                    {status.desc}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}
           {props.type === "MOBILE_DIAGNOSTIC" && (
          <Form.Group as={Col} md={12}>
            <Form.Label>
              <FormattedMessage id="GENERAL.NAME"/>
            </Form.Label>
            <Form.Control
              name="name"
              type="text"
              placeholder={`${intl.formatMessage({
                id: "GENERAL.NAME",
              })}`}
              ref={register({required: false})}
            />
          </Form.Group>
        )}
          <div className="col-xxl-12 text-right">
            <Button
              type="submit"
              disabled={loading}
            >
              <FormattedMessage id="GENERAL.SAVE" />
            </Button>
            {props.report && (
              <Button
                type="button"
                disabled={loading}
                className="ml-2"
                onClick={() => onExportingReport()}
              >
                <FormattedMessage id="GENERAL.EXPORT_REPORT" />
              </Button>
            )}
          </div>
        </Form.Row>
      </Form>
    </>
  );
}
